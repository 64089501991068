import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { calculateSeatPosition, useGameStore } from '@/entities/game'

import { getUserSubtitle, getUserTitle } from '../lib/user.utils'
import type { UserInGameProps } from '../model/user.types'

export function useUserInGame(user: UserInGameProps) {
	const { t } = useTranslation()
	const { game, playerSeat } = useGameStore()

	const [turnData, setTurnData] = useState<{ endAt: string; value: boolean }>({
		value: game.current_turn.user_id === user.id,
		endAt: game.current_turn.end_at ?? new Date().toISOString()
	})

	useEffect(() => {
		if (game.current_turn.user_id) {
			setTurnData({ value: game.current_turn.user_id === user.id, endAt: game.current_turn.end_at })
		} else {
			setTurnData({ value: false, endAt: new Date().toISOString() })
		}
	}, [game.current_turn, user.id])

	const isPlayer = playerSeat === user.index + 1
	const isBetDisplay = user.winner ? false : Number(user.last_bet) > 0

	const roundData = useMemo(
		() => ({
			isSB: game.small_blind_position === user.index + 1,
			isBB: game.big_blind_position === user.index + 1,
			isDealer: game.current_dealer === user.index + 1
		}),
		[game.small_blind_position, game.big_blind_position, game.current_dealer, user.index]
	)

	const pos = calculateSeatPosition(user.seat, 6, 39)

	const title = t(
		getUserTitle({
			username: user.username,
			last_action: user.last_action,
			winner: user.winner,
			player_balance: user.player_balance
		}),
		user.last_action === 'raise' ? { raise: ` ${user.last_bet}$` } : {}
	)
	const subtitle = t(
		getUserSubtitle({
			ready: user.ready,
			player_balance: user.player_balance,
			winner: user.winner
		})
	)

	return {
		title,
		subtitle,

		pos,
		roundData,
		turnData,

		isPlayer,
		isBetDisplay
	}
}

import { useGameActions } from '..'
import { useEffect, useMemo, useState } from 'react'

import { useGameStore } from '../model/game.store'
import type { GameWS } from '../model/game.types'

import { useGameUser } from './useGameUser'

export function useGameControls() {
	const { ws, game, premove } = useGameStore()
	if (!ws) throw new Error('GameControls :: Game WebSocket is null')

	const player = useGameUser()
	if (!player) throw new Error('GameControls :: Player is not at table')

	const availableActions = game.available_actions ?? []

	const limits = {
		max: player.player_balance,
		min: player.player_balance >= game.blind_amount ? game.blind_amount : player.player_balance
	}

	const { gameSetPremove } = useGameActions()

	const premoveAction = premove?.action ?? null
	const isCurrentTurn = useMemo(() => {
		if (game.current_turn.user_id === player.id) return true
		else return false
	}, [game.current_turn, player])

	useEffect(() => {
		if (isCurrentTurn && premove) {
			const sendAction = async () => await ws.send<GameWS['action']>(premove)
			sendAction()
			gameSetPremove(null)
		} else return
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCurrentTurn])

	// Buttons handling
	const onCheck = async () => {
		resetRaise()
		if (isCurrentTurn) {
			await ws.send<GameWS['action']>({ type: 'action', action: 'check' })
		} else {
			if (premove?.action === 'check') gameSetPremove(null)
			else gameSetPremove({ type: 'action', action: 'check' })
		}
	}

	const onCall = async () => {
		resetRaise()
		if (isCurrentTurn) {
			await ws.send<GameWS['action']>({ type: 'action', action: 'call' })
		} else {
			if (premove?.action === 'call') gameSetPremove(null)
			else gameSetPremove({ type: 'action', action: 'call' })
		}
	}

	const onRaise = async (amount: number) => {
		if (isCurrentTurn) {
			await ws.send<GameWS['action']>({ type: 'action', action: 'raise', amount })
		} else {
			gameSetPremove({ type: 'action', action: 'raise', amount })
		}
		resetRaise()
	}

	const onFold = async () => {
		resetRaise()
		if (isCurrentTurn) {
			await ws.send<GameWS['action']>({ type: 'action', action: 'fold' })
		} else {
			if (premove?.action === 'fold') gameSetPremove(null)
			else gameSetPremove({ type: 'action', action: 'fold' })
		}
	}

	const handleRaise = () => {
		if (!isCurrentTurn && premove?.action === 'raise') gameSetPremove(null)
		else {
			if (raise === 0) setRaise(limits.min)
			else onRaise(raise)
		}
	}

	const [raise, setRaise] = useState(0)
	const resetRaise = () => setRaise(0)
	const onChangeRaise = (value: number) => setRaise(value)

	const onAllIn = () => setRaise(player.player_balance)

	const onSetPot = () => {
		if (player.player_balance >= game.pot) setRaise(game.pot)
		else onAllIn()
	}

	const onSetHalfPot = () => {
		const halfOfPot = Math.floor(game.pot / 2)
		if (player.player_balance >= halfOfPot) setRaise(halfOfPot)
		else onAllIn()
	}

	return {
		onFold,
		onCheck,
		onCall,
		handleRaise,

		onSetHalfPot,
		onSetPot,
		onAllIn,
		onChangeRaise,

		raise,
		limits,
		availableActions,

		isCurrentTurn,
		premoveAction
	}
}

import { chatStoreManager } from './chat.store'
import type { ChatType, TChatMessage } from './chat.types'

export function chatAddMessage(data: TChatMessage) {
	chatStoreManager(s => {
		if (data.type === 'chat_log') s.logs.add(data)
		else if (data.type === 'chat_message') s.msgs.add(data)
		else if (data.type === 'error') {
			s.logs.add({ type: 'chat_log', message: `Error: ${data.message}` })
		} else throw new Error('Wrong message type')
	})
}

export function chatSendMessage(message: string) {
	chatStoreManager(s => {
		s.isSendingMsg = true
	})

	const res = new Promise(res =>
		setTimeout(() => {
			{
				res(message)
				chatStoreManager(s => {
					s.isSendingMsg = false
				})
			}
		}, 1000)
	)

	return res
}

export function chatToggleVisibility(visible?: boolean) {
	chatStoreManager(s => {
		if (typeof visible !== 'undefined') s.isVisible = visible
		else s.isVisible = !s.isVisible
	})
}

export function chatSelectChat(chat: ChatType) {
	chatStoreManager(s => {
		s.selectedChat = chat
	})
}

export function chatChangeAutoScroll(val: boolean) {
	chatStoreManager(s => {
		s.autoScroll = val
	})
}

import { Icon } from '../Icon/Icon'

import c from './Chip.module.scss'

interface ChipProps {
	w?: string
	isDealer?: boolean
	color?: string
}

export function Chip({
	w = '1.5625rem',
	color = 'var(--color-black-0)',
	isDealer = false
}: ChipProps) {
	return (
		<div className={c['chip-wrapper']} style={{ width: w, height: w, color }}>
			<Icon className={c['chip']} name={isDealer ? 'chip-dealer' : 'chip'} />
		</div>
	)
}

import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { createStoreManager } from '@/shared/utils'

import { FilesStore } from './files.types'

export const useFilesStore = create(
	persist<FilesStore>(() => ({ cards: [] }), {
		name: 'cards',
		storage: createJSONStorage(() => sessionStorage)
	})
)

export const filesStoreManager = createStoreManager(useFilesStore)

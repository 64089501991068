import { useEffect, useRef } from 'react'

import { useChatActions } from '@/components/Chat'
import { useChatStore } from '@/components/Chat/model/chat.store'

import { ChatMessage } from '../../ChatMessage/ChatMessage'
import c from '../ChatWindow.module.scss'

export function DealerChat() {
	const { logs: msgs, autoScroll } = useChatStore()
	const { chatChangeAutoScroll } = useChatActions()
	const messages = Array.from(msgs)

	const chatRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		if (autoScroll && chatRef.current) {
			chatRef.current.scrollTop = chatRef.current.scrollHeight
		}
	}, [autoScroll, msgs])

	const handleScroll = () => {
		if (chatRef.current) {
			const isAtBottom =
				chatRef.current.scrollHeight - chatRef.current.scrollTop === chatRef.current.clientHeight
			chatChangeAutoScroll(isAtBottom)
		}
	}

	return (
		<div className={c['chat-window--dealer']} onScroll={handleScroll} ref={chatRef}>
			{messages.map((m, i) => (
				<ChatMessage key={`${m.type}-${i}`} {...m} />
			))}
		</div>
	)
}

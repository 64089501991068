import { withLayout } from '@/app/layouts/withLayout'

import { Chip } from '@/shared/ui'

import c from './DevPage.module.scss'

export function DevPage() {
	return (
		<div className={c['page-game']}>
			<Chip color='var(--color-gold-2)' />
			<Chip w='5.625rem' isDealer />
		</div>
	)
}

const GamePageWithLayout = withLayout(DevPage)
export default GamePageWithLayout

import type { HandRankingSection, SupportPage } from '../model/support.types'

export const supportSwitchOptions: { value: SupportPage; label: string }[] = [
	{ value: 'rules', label: 'support_btn-rules' },
	{ value: 'ranking', label: 'support_btn-ranking' }
]

export const handRankingSections: HandRankingSection[] = [
	{
		k: 'rf',
		title: 'support_ranking-rf-title',
		cards: [
			{ name: 'AH', in_combo: true },
			{ name: 'KH', in_combo: true },
			{ name: 'QH', in_combo: true },
			{ name: 'JH', in_combo: true },
			{ name: '10H', in_combo: true }
		],
		description: 'support_ranking-rf-desc'
	},
	{
		k: 'sf',
		title: 'support_ranking-sf-title',
		cards: [
			{ name: 'JC', in_combo: true },
			{ name: '10C', in_combo: true },
			{ name: '9C', in_combo: true },
			{ name: '8C', in_combo: true },
			{ name: '7C', in_combo: true }
		],
		description: 'support_ranking-sf-desc'
	},
	{
		k: '4',
		title: 'support_ranking-4-title',
		cards: [
			{ name: '9S', in_combo: true },
			{ name: '9D', in_combo: true },
			{ name: '9H', in_combo: true },
			{ name: '9C', in_combo: true },
			{ name: '2S', in_combo: false }
		],
		description: 'support_ranking-4-desc'
	},
	{
		k: 'fh',
		title: 'support_ranking-fh-title',
		cards: [
			{ name: 'QC', in_combo: true },
			{ name: 'QD', in_combo: true },
			{ name: 'QH', in_combo: true },
			{ name: '10C', in_combo: true },
			{ name: '10D', in_combo: true }
		],
		description: 'support_ranking-fh-desc'
	},
	{
		k: 'f',
		title: 'support_ranking-f-title',
		cards: [
			{ name: 'KC', in_combo: true },
			{ name: 'JC', in_combo: true },
			{ name: '8C', in_combo: true },
			{ name: '7C', in_combo: true },
			{ name: '4C', in_combo: true }
		],
		description: 'support_ranking-f-desc'
	},
	{
		k: 's',
		title: 'support_ranking-s-title',
		cards: [
			{ name: '9C', in_combo: true },
			{ name: '8D', in_combo: true },
			{ name: '7H', in_combo: true },
			{ name: '6S', in_combo: true },
			{ name: '5D', in_combo: true }
		],
		description: 'support_ranking-s-desc'
	},
	{
		k: '3',
		title: 'support_ranking-3-title',
		cards: [
			{ name: 'KS', in_combo: true },
			{ name: 'KC', in_combo: true },
			{ name: 'KD', in_combo: true },
			{ name: 'QD', in_combo: false },
			{ name: '2S', in_combo: false }
		],
		description: 'support_ranking-3-desc'
	},
	{
		k: '2-2',
		title: 'support_ranking-2-2-title',
		cards: [
			{ name: 'JD', in_combo: true },
			{ name: 'JH', in_combo: true },
			{ name: '6S', in_combo: true },
			{ name: '6C', in_combo: true },
			{ name: '2S', in_combo: false }
		],
		description: 'support_ranking-2-2-desc'
	},
	{
		k: '2',
		title: 'support_ranking-2-title',
		cards: [
			{ name: '10C', in_combo: true },
			{ name: '10D', in_combo: true },
			{ name: '2D', in_combo: false },
			{ name: 'QD', in_combo: false },
			{ name: '8D', in_combo: false }
		],
		description: 'support_ranking-2-desc'
	},
	{
		k: 'hc',
		title: 'support_ranking-hc-title',
		cards: [
			{ name: 'KS', in_combo: true },
			{ name: '10H', in_combo: false },
			{ name: '8C', in_combo: false },
			{ name: '7C', in_combo: false },
			{ name: '4S', in_combo: false }
		],
		description: 'support_ranking-hc-desc'
	}
]

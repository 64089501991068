import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { IS_DEV_ENV } from '@/app/configs/envs'

import { useFilesActions } from '@/entities/files'
import { useUserActions } from '@/entities/user'

import getRandomLogo from '../lib/getRandomLogo'

export function useLoadingPage() {
	const logo = getRandomLogo()
	const nav = useNavigate()

	const { userAuth } = useUserActions()
	const { filesGetCards } = useFilesActions()

	useEffect(() => {
		const fetchData = async () => {
			if (!IS_DEV_ENV) {
				await userAuth()
				await filesGetCards()
			}

			nav('/menu')
		}

		fetchData()
	}, [nav, userAuth, filesGetCards])

	return { logo }
}

import { useLayoutEffect } from 'react'

import { withHeaderLayout } from '@/app/layouts/withHeaderLayout'

import { MenuContent, MenuFooter } from '@/components/Menu'

import { useUserActions } from '@/entities/user'

import c from './MenuPage.module.scss'

function MenuPage() {
	const { userGetMe, userAuthRefresh } = useUserActions()

	useLayoutEffect(() => {
		const fetch = async () => {
			try {
				await userGetMe()
			} catch (e) {
				if (e === 401) {
					try {
						await userAuthRefresh()
						await userGetMe()
					} catch (refreshError) {
						console.error('Failed to refresh token:', refreshError)
						throw refreshError
					}
				} else {
					console.error('Unexpected error while fetching user data:', e)
					throw e
				}
			}
		}

		fetch()
	}, [userAuthRefresh, userGetMe])

	return (
		<div className={c['page-menu']}>
			<MenuContent />
			<MenuFooter />
		</div>
	)
}

const MenuPageWithLayout = withHeaderLayout(MenuPage)
export default MenuPageWithLayout

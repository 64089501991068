import { useEffect, useRef } from 'react'

import { GAME_ROUND_TIMEOUT } from '@/app/configs/envs'

import { useCreateOverlay, useOverlay } from '@/components/Overlay'

import { type GameWS, useGameActions, useGameStore } from '@/entities/game'
import { useUserStore } from '@/entities/user'

import { useRedirect } from '@/shared/hooks'

export function useGameContent() {
	const { ws, game, autoRebuy } = useGameStore()
	if (!ws) throw new Error('Game WebSocket is null at GameContent')

	const { gameChangeState } = useGameActions()

	// Buy-in overlay
	useCreateOverlay('buy-in-overlay')
	const [isOverlayVisible, toggleBuyInOverlay] = useOverlay('buy-in-overlay')

	// Round end
	const rebuy = useRef(autoRebuy)
	useEffect(() => {
		rebuy.current = autoRebuy
	}, [autoRebuy])

	const playerId = useUserStore(s => s.tg_uid)
	const roundEndTimeout = useRef<NodeJS.Timeout | null>(null)

	const handleRoundEnd = async (data: GameWS['roundEnd']) => {
		console.log('Round end ', data, playerId, rebuy.current)
		const player = data.players.find(p => p.user_id === playerId)
		if (!player) throw new Error(`Player with id ${playerId} is not in round-end data`)
		const { balance } = player

		if (roundEndTimeout.current) clearTimeout(roundEndTimeout.current)
		roundEndTimeout.current = setTimeout(async () => {
			if (balance > game.blind_amount) {
				await ws.send<GameWS['ready']>({ type: 'ready', buy_in: balance })
			} else if (balance <= game.blind_amount) {
				if (rebuy.current.active) {
					await ws.send<GameWS['ready']>({ type: 'ready', buy_in: rebuy.current.ammount })
				} else {
					toggleBuyInOverlay(true)
				}
			}

			roundEndTimeout.current = null
		}, GAME_ROUND_TIMEOUT)
	}

	const redirect = useRedirect('/menu')
	const onSocketClose = (e: unknown) => {
		void e
		redirect()
	}

	// Subscribe to ws game
	useEffect(() => {
		ws.on<GameWS['roundEnd']>('round_end', handleRoundEnd)
		ws.on('game_state', gameChangeState)
		ws.on('close', onSocketClose)

		return () => {
			if (roundEndTimeout.current) clearTimeout(roundEndTimeout.current)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return { isOverlayVisible }
}

import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import { handRankingSections } from '../../lib/support.constants'
import { useSupportStore } from '../../model/support.store'
import { ComboSection } from '../ComboSection/ComboSection'

import c from './SupportContent.module.scss'

export function SupportContent() {
	const { t } = useTranslation()
	const selectedPage = useSupportStore(s => s.selectedPage)

	return (
		<div className={c['support-content']}>
			{selectedPage === 'rules' && <ReactMarkdown>{t('support_rules')}</ReactMarkdown>}

			{selectedPage === 'ranking' &&
				handRankingSections.map(s => (
					<ComboSection
						key={s.k}
						title={t(s.title)}
						cards={s.cards}
						description={t(s.description)}
					/>
				))}
		</div>
	)
}

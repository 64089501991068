import { useChatActions } from '..'
import cn from 'classnames'

import { Icon } from '@/shared/ui'

import { useChatStore } from '../model/chat.store'

import c from './Chat.module.scss'

import { ChatControls } from './ChatControls/ChatControls'
import { ChatWindow } from './ChatWindow/ChatWindow'

export function Chat() {
	const isVisible = useChatStore(s => s.isVisible)
	const { chatToggleVisibility, chatChangeAutoScroll } = useChatActions()
	const onExpand = () => {
		chatChangeAutoScroll(true)
		chatToggleVisibility()
	}

	return (
		<div className={cn(c.chat, isVisible && c['shown'])}>
			<ChatWindow />
			<ChatControls />
			<button className={c['chat-btn']} onClick={onExpand}>
				<Icon name='chevrons-down' w={33} h={12} className={c['chat-btn-icon']} />
			</button>
		</div>
	)
}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/shared/ui'

import { useGameStore } from '../../model/game.store'
import type { GameWS } from '../../model/game.types'

import c from './ShowMuckControls.module.scss'

export function ShowMuckControls() {
	const { t } = useTranslation()

	const { ws } = useGameStore()
	if (!ws) throw new Error('ShowMuckControls :: Game WebSocket is null')

	const [buttonPressed, setButtonPressed] = useState<boolean>(false)

	const handleShowMock = (action: 'show' | 'muck') => async () => {
		setButtonPressed(true)
		await ws.send<GameWS['show_mock']>({ type: 'show_muck', action })
	}

	return (
		<div className={c['show-muck-controls']}>
			<img
				width={123}
				height={110}
				src={'/show-cards.svg'}
				style={{ opacity: buttonPressed ? 0.5 : 1 }}
			/>
			<Button
				aria-disabled={buttonPressed}
				disabled={buttonPressed}
				color='green'
				text={t('show/muck-show')}
				className={c['show-btn']}
				onClick={handleShowMock('show')}
			/>
		</div>
	)
}

import { formatNumber } from '@/shared/utils'

import type { UserInGame } from '../model/user.types'

export const getUserTitle = (
	user: Pick<UserInGame, 'last_action' | 'username' | 'winner' | 'player_balance'>
) => {
	if (user.winner) return 'win'
	else if (user.last_action) {
		if (['raise', 'call'].includes(user.last_action) && user.player_balance <= 0)
			return 'game_action-all-in'
		else return `game_action-${user.last_action}`
	} else return user.username
}

export const getUserSubtitle = (user: Pick<UserInGame, 'ready' | 'player_balance' | 'winner'>) => {
	if (user.winner) return `+${formatNumber(user.winner)}$`
	else if (user.ready) return `${user.player_balance}$`
	else return 'waiting'
}
